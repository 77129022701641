@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer components {
  .hamburger div:first-child {
    @apply rotate-45 translate-y-1.5;
  }
  .hamburger div:last-child {
    @apply -rotate-45 -translate-y-1;
  }
}

/* 
@font-face {
  font-family: 'Product Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans'), local('OpenSans'), url(https://fonts.gstatic.com/s/productsans/v5/HYvgU2fE2nRJvZ5JFAumwegdm0LZdjqr5-oayXSOefg.woff2) format('woff2');
} */

.swiper-horizontal{
overflow: visible !important;
} 

.logo{
  font-family: 'Syne', sans-serif;
}

.poppins{
  font-family: 'Poppins', sans-serif;
}

.mont{
font-family: 'Montserrat', sans-serif;
/* line-height: 4rem; */
}



#bg-clip{
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 80%);
}

.mesh{

  background-image: radial-gradient(at 67% 6%, rgba(30, 64, 175, 0.15) 0, transparent 95%),
  radial-gradient(at 35% 96%, rgba(91, 33, 182, 0.15) 0, transparent 90%),
  radial-gradient(at 31% 95%, rgba(232, 121, 249, 0.15) 0, transparent 36%),
  radial-gradient(at 77% 61%, rgba(29, 78, 216, 0.15) 0, transparent 54%),
  radial-gradient(at 46% 79%, rgba(4, 120, 87, 0.15) 0, transparent 89%),
  radial-gradient(at 67% 89%, rgba(254, 202, 202, 0.15) 0, transparent 43%);
  
}


.contact-bg{
  background-image: radial-gradient(at 67% 6%, rgba(30, 64, 175, 0.05) 0, transparent 95%),
  radial-gradient(at 35% 96%, rgba(91, 33, 182, 0.05) 0, transparent 90%),
  radial-gradient(at 31% 95%, rgba(232, 121, 249, 0.05) 0, transparent 36%),
  radial-gradient(at 77% 61%, rgba(29, 78, 216, 0.05) 0, transparent 54%),
  radial-gradient(at 46% 79%, rgba(4, 120, 87, 0.05) 0, transparent 89%),
  radial-gradient(at 67% 89%, rgba(254, 202, 202, 0.05) 0, transparent 43%);

}

#hero-img{
  background-image: url('Images/computer.jpg');
  /* background: #fff; */ 
}


#faq-img{
  /* background: #fff; */ 
  background-image: url('Images/computer.jpg');
}

#contact-img{
  background-image: url('Images/bg-louver.jpg');
  /* background: #fff; */ 
}



#cta{
 
  /* background-color: rgb(51, 65, 85); */
 background-image: 
  radial-gradient(at 62% 74%, rgba(22, 16, 102, 0.8) 0, rgba(22, 18, 80, 0.2) 85%),
  radial-gradient(at 38% 96%, rgba(95, 95, 95, 0.8) 0, rgba(59, 59, 59, 0.4) 11%),
  radial-gradient(at 87% 71%, rgba(99, 0, 197, 0.8) 0, rgba(65, 0, 129, 0.5) 11%),
  radial-gradient(at 10% 49%, rgba(0, 109, 78, 0.8) 0, rgba(0, 90, 65, 0.4) 97%),
  radial-gradient(at 26% 33%, rgba(143, 83, 0, 0.8) 0, rgba(117, 68, 0, 0.2) 87%),
  radial-gradient(at 0% 86%, rgba(0, 0, 0, 0.8) 0, rgba(0, 0, 0, 0.4) 45%);
  /* background-blend-mode: hue; */
}

#brands{
  background-image: url('Images/cover.jpg');
  background-position: center;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  background-blend-mode: multiply;
  cursor: pointer; /* Add a pointer on hover */
}

.inner-s{
  box-shadow: inset 0 2px 14px 0 rgb(0 0 0 / 0.5),0 25px 50px -12px rgb(220 38 38);
}




@keyframes slide-fade-in {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animate-slide-fade-in {
  animation: slide-fade-in 1s ease-out forwards;
}



@keyframes slide-img {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.anim-slide-img {
  animation: slide-img 1s ease-out forwards;
}



@keyframes slide-fade-product {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.animate-slide-fade-product {
  animation: slide-fade-product 1s ease-out forwards;
}



@keyframes slide-product {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.anim-product {
  animation: slide-product 1s ease-out forwards;
}


@media (max-width: 768px) {
  #bg-clip{
    clip-path: polygon(0 0, 100% 0%, 100% 90%, 0 80%);
  }
  .anim-product {
    animation: slide-fade-product 1s ease-out forwards;
  }
  .animate-slide-fade-product {
    animation: slide-product 1s ease-out forwards;
  }
}


